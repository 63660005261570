import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import "./OptOutConsent.scss"

const OptOutConsent = () => {
  const { t } = useTranslation("common")
  const disableAnalytics = () => {
    document.cookie = `gatsby-gdpr-google-analytics=false; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; secure`
  }
  return (
    <button className="optout" onClick={disableAnalytics}>
      {t("CONSENT.OPT_OUT")}
    </button>
  )
}

export default OptOutConsent
